<div *ngIf="!IsMobile()" class="elemc" style="overflow: hidden; z-index: 4; transform: translate(0%,-3%); ">

  <div class="flexible-container Elements" style="position: relative; clip-path: polygon(0 0, 100% 0, 100% 98%, 0% 100%);">

    <img loading="lazy" src="assets/assets_web_2/masque_section_4.webp" alt="Graphic" style="position: absolute; transform: translate(0%,2%); height: max(1000px,110vh); width: 50vw; bottom: 0; right: 0; pointer-events: none; user-select: none; z-index: 4 ">

    <svg height="max(1000px,calc( 110vh - 10px))" width="50vw" id="container" style="position: absolute; bottom: 0; right: 0; pointer-events: none; z-index: 4; opacity: 1;"></svg>

    <div id="video_overlays" class="elemVO pt-5" style="vertical-align:central">

      <div class="row justify-content-around pt-3" style="display: flex; align-items: center;">
        <div class="col-md mx-lg-5">
          <h1 id="title_s" class="px-lg-0">Master the elements</h1>
          <p class="px-3 px-lg-0">
            Customize your character by selecting from a wide range of spells and talents
            that allow you to unleash the power of the elements.
          </p>
        </div>
      </div>

      <div class="ElementsArt">
        <img loading="lazy" class="FireRelic" alt="FireRelic" src="assets/assets_web_2/FireRelic.webp" style=" filter: drop-shadow(5px 5px 50px #DC8E35); width: max(30vw,250px); position: absolute; z-index: -1; right: 0; margin-top: -6vh; pointer-events: none; user-select: none; " />
        <img loading="lazy" class="WaterRelic Op0" alt="WaterRelic" src="assets/assets_web_2/WaterRelic.webp" style="filter: drop-shadow(5px 5px 50px #3cc9ca); width: max(30vw,250px); position: absolute; z-index: -1; right: 1vw; margin-top: -2vh; pointer-events: none; user-select: none; " />
        <img loading="lazy" class="AirRelic Op0" alt="AirRelic" src="assets/assets_web_2/AirRelic.webp" style="filter: drop-shadow(5px 5px 50px #fffed5); width: max(30vw,250px); position: absolute; z-index: -1; right: 0; margin-top: -8vh; pointer-events: none; user-select: none; " />
        <img loading="lazy" class="EarthRelic Op0" alt="EarthRelic" src="assets/assets_web_2/EarthRelic.webp" style="filter: drop-shadow(5px 5px 70px #347437); width: max(30vw,250px); position: absolute; z-index: -1; right: -1vw; margin-top: -3vh; pointer-events: none; user-select: none; " />
      </div>

      <div>

        <div class="px-5" style="width: 177px; padding-top: 5vh ">

          <a class="btn btn-link" style="cursor: pointer;" (click)="ClickSpellFire()" (mouseenter)="FireElementMouseEnter()" (mouseleave)="FireElementMouseLeave()">
            <svg viewBox="0 0 200 66" width="min(200px,25vh)" height="min(66px,25vh)" preserveAspectRatio="xMaxYMax">
              <rect height="66px" width="200px" />
              <rect id="Rectangle_60" loading="lazy" alt="Icon" width="25px" height="25px" transform="translate(15 33) rotate(-45)" />
              <rect class="RectFB" id="Rectangle_60_fill" loading="lazy" alt="Icon" width="25px" height="25px" transform="translate(15 33) rotate(-45)" />
              <text x="60px" fill="white" y="45px" style="font-size: 1.4rem; left: 5vw; ">
                <tspan>Fire</tspan>
              </text>
            </svg>
          </a>

          <a class="btn btn-link" style="cursor: pointer;" (click)="ClickSpellAir()" (mouseenter)="AirElementMouseEnter()" (mouseleave)="AirElementMouseLeave()">
            <svg viewBox="0 0 200 66" width="min(200px,25vh)" height="min(66px,25vh)" preserveAspectRatio="xMaxYMax">
              <rect height="66px" width="200px" />
              <rect id="Rectangle_60" loading="lazy" alt="Icon" width="25px" height="25px" transform="translate(15 33) rotate(-45)" />
              <rect style="opacity:0" class="RectFW" id="Rectangle_60_fill" loading="lazy" alt="Icon" width="25px" height="25px" transform="translate(15 33) rotate(-45)" />
              <text x="60px" fill="white" y="45px" style="font-size: 1.4rem; left: 5vw; ">
                <tspan>Wind</tspan>
              </text>
            </svg>
          </a>

          <a class="btn btn-link" style="cursor: pointer;" (click)="ClickSpellWater()" (mouseenter)="WaterElementMouseEnter()" (mouseleave)="WaterElementMouseLeave()">
            <svg viewBox="0 0 200 66" width="min(200px,25vh)" height="min(66px,25vh)" preserveAspectRatio="xMaxYMax">
              <rect height="66px" width="200px" />
              <rect id="Rectangle_60" loading="lazy" alt="Icon" width="25px" height="25px" transform="translate(15 33) rotate(-45)" />
              <rect style="opacity:0" class="RectFWa" id="Rectangle_60_fill" loading="lazy" alt="Icon" width="25px" height="25px" transform="translate(15 33) rotate(-45)" />
              <text x="60px" fill="white" y="45px" style="font-size: 1.4rem; left: 5vw; ">
                <tspan>Water</tspan>
              </text>
            </svg>
          </a>

          <a class="btn btn-link" style="cursor: pointer;" (click)="ClickSpellEarth()" (mouseenter)="EarthElementMouseEnter()" (mouseleave)="EarthElementMouseLeave()">
            <svg viewBox="0 0 200 66" width="min(200px,25vh)" height="min(66px,25vh)" preserveAspectRatio="xMaxYMax">
              <rect height="66px" width="200px" />
              <rect id="Rectangle_60" loading="lazy" alt="Icon" width="25px" height="25px" transform="translate(15 33) rotate(-45)" />
              <rect style="opacity:0" class="RectFE" id="Rectangle_60_fill" loading="lazy" alt="Icon" width="25px" height="25px" transform="translate(15 33) rotate(-45)" />
              <text x="60px" fill="white" y="45px" style="font-size: 1.4rem; left: 5vw; ">
                <tspan>Earth</tspan>
              </text>
            </svg>
          </a>

        </div>

        <div class="raw small px-5" style="padding-top:5vh">

          <div class="FireText" style=" position: absolute; margin-right: 5vh">
            <h4 class="px-3 px-lg-0">Let it burn</h4>
            <p class="px-3 px-lg-0">
              Fire is the element of wrath and destruction,
              an energy as powerful as it is dangerous.
              Use its magic to deal devastating damage or
              make enemies vulnerable to follow-up attacks.
            </p>
          </div>
          <div class="AirText" style=" position: absolute; margin-right: 5vh" [ngClass]="{'Op0': !IsMobile()}">
            <h4 class="px-3 px-lg-0">Ride the Storm</h4>
            <p class="px-3 px-lg-0">
              Air magic impedes the enemies’ speed and
              mobility while boosting those of allies.
              Some air spells can also be used tactically,
              for example for surprise attacks or diversions.
            </p>
          </div>
          <div class="WaterText" style=" position: absolute; margin-right: 5vh" [ngClass]="{'Op0': !IsMobile()}">
            <h4 class="px-3 px-lg-0">Turn the Tide</h4>
            <p class="px-3 px-lg-0">
              Water is the antipode to fire and
              stands for regeneration and healing.
              In combat, spells associated with water magic keep allies alive
              and protect them from harm.
            </p>
          </div>
          <div class="EarthText" style=" position: absolute; margin-right: 5vh" [ngClass]="{'Op0': !IsMobile()}">
            <h4 class="px-3 px-lg-0">Stand your ground</h4>
            <p class="px-3 px-lg-0">
              Earth represents resilience and the ability to endure punishment.
              Some spells associated with earth magic also remove
              debuffs from allies and sabotage your foes abilities.
            </p>
          </div>
        </div>

      </div>
    </div>
    <div id="SoulCastSpellVideo">
      <div #playerSpellContainer style="position: absolute; top: 50%; left: 50%; transform: translate(-75% ,-50%)"></div>
    </div>
  </div>
  <img loading="lazy" src="assets/assets_web_2/separateur.webp" alt="border" style="position: relative;padding-bottom:20px; transform: translate(0%,-65%) rotate(-0.8deg); height: auto; width: 95%; left: 2.5%; bottom: 0; pointer-events: none; z-index: 5">
</div>

<div *ngIf="IsMobile()">
  <h1 id="title_s" style="text-align:center">Master the elements</h1>
  <p class="px-4 py-2">
    Customize your character by selecting 4 spells
    that allow you to unleash the power of the elements.
  </p>

  <div class='embed-container' style="position:relative">
    <svg viewBox="0 0 300 300" width="min(300px,50vw)" height="min(150px,25vh)" style="position: absolute; bottom: 0">
      <image x="-85" y="-20" loading="lazy" href="assets/assets_web_2/FireRelic.webp" alt="Icon" width="350px" height="350px" />
      <text width="400" height="160" x="102.5px" fill="white" y="250px" text-anchor="middle" style="font-size: 80px; left: 5vw;">
        <tspan>Fire</tspan>
      </text>
    </svg>
    <div #playerMagicFireMobile></div>
  </div>
  <p class="px-4 py-4">
    Fire is the element of wrath and destruction,
    an energy as powerful as it is dangerous.
    Use its magic to deal devastating damage or
    make enemies vulnerable to follow-up attacks.
  </p>

  <div class='embed-container' style="position:relative">
    <svg viewBox="0 0 300 300" width="min(300px,50vw)" height="min(150px,25vh)" style="position: absolute; bottom: 0">
      <image x="-85" y="-50" loading="lazy" href="assets/assets_web_2/AirRelic.webp" alt="Icon" width="400px" height="400px" />
      <text width="400" height="160" x="102.5px" fill="white" y="250px" text-anchor="middle" style="font-size: 80px; left: 5vw;">
        <tspan>Air</tspan>
      </text>
    </svg>
    <div #playerMagicAirMobile></div>
  </div>
  <p class="px-4 py-4">
    Air magic impedes the enemies’ speed and
    mobility while boosting those of allies.
    Some air spells can also be used tactically,
    for example for surprise attacks or diversions.
  </p>

  <div class='embed-container' style="position:relative">
    <svg viewBox="0 0 300 300" width="min(300px,50vw)" height="min(150px,25vh)" style="position: absolute; bottom: 0">
      <image x="-80" y="0" loading="lazy" href="assets/assets_web_2/WaterRelic.webp" alt="Icon" width="400px" height="400px" />
      <text width="400" height="160" x="102.5px" fill="white" y="250px" text-anchor="middle" style="font-size: 80px; left: 5vw;">
        <tspan>Water</tspan>
      </text>
    </svg>
    <div #playerMagicWaterMobile></div>
  </div>
  <p class="px-4 py-4">
    Water is the antipode to fire and
    stands for regeneration and healing.
    In combat, spells associated with water magic keep allies alive
    and protect them from harm.
  </p>

  <div class='embed-container' style="position:relative">
    <svg viewBox="0 0 300 300" width="min(300px,50vw)" height="min(150px,25vh)" style="position: absolute; bottom: 0">
      <image x="-75" y="0" loading="lazy" href="assets/assets_web_2/EarthRelic.webp" alt="Icon" width="400px" height="400px" />
      <text width="400" height="160" x="100.5px" fill="white" y="250px" text-anchor="middle" style="font-size: 80px; left: 5vw;">
        <tspan>Earth</tspan>
      </text>
    </svg>
    <div #playerMagicEarthBowMobile></div>
  </div>
  <p class="px-4 py-4">
    Earth represents resilience and the ability to endure punishment.
    Some spells associated with earth magic also remove
    debuffs from allies and sabotage your foes abilities.
  </p>

</div>
