import { Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent,TimeoutInterceptor } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { HomeVideoComponent } from './home-video/home-video.component';
import { AboutVideoComponent } from './about-video/about-video.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MagicElementsComponent } from './magic-elements/magic-elements.component';
import { SocialOrderComponent } from './social-order/social-order.component';
import { GameplayActivitiesComponent } from './gameplay-activities/gameplay-activities.component';
import { WeaponParallaxComponent } from './weapon-parallax/weapon-parallax.component';
import { WeaponTemplatedListComponent } from './weapon-templated-list/weapon-templated-list.component';
import { AccountComponent } from './account/account.component';
import { FrontPageComponent } from './front-page/front-page.component';
import { HttpClientModule, HttpClientXsrfModule, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpXsrfTokenExtractor, HTTP_INTERCEPTORS  } from '@angular/common/http';
import { LoginComponent } from './login/login.component';
import { AuthGuardService } from './auth-guard.service';
import { RegisterComponent } from './register/register.component';
import { AccountOverviewComponent } from './account-overview/account-overview.component';
import { AccountSecurityComponent } from './account-security/account-security.component';
import { AccountAssistanceComponent } from './account-assistance/account-assistance.component';
import { CookieService } from 'ngx-cookie-service';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { NgxStripeModule } from 'ngx-stripe';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { AccountOrdersComponent } from './account-orders/account-orders.component';
import { OrdersTemplatedListComponent } from './orders-templated-list/orders-templated-list.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ShopComponent } from './shop/shop.component';
import { ProductsTemplatedListComponent } from './products-templated-list/products-templated-list.component';
import { HudComponent } from './hud/hud.component';
import { FooterComponent } from './footer/footer.component';
import { NewsletterSubscribeComponent } from './newsletter-subscribe/newsletter-subscribe.component';
import { FAQComponent } from './faq/faq.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { CookieComponent } from './cookie/cookie.component';
import { ProductComponent } from './product/product.component';
import { LoreComponent } from './lore/lore.component';
import { RedirectComponent } from './redirect/redirect.component';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ThanksComponent } from './thanks/thanks.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { TermsAndConditionsModalComponent } from './terms-and-conditions-modal/terms-and-conditions-modal.component';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'http://localhost:4200/' // or 'your.domain.com'
  },
  "position": "bottom-left",
  "theme": "classic",
  "palette": {
    "popup": {
      "background": "rgb(50, 50, 50)",
      "text": "#ffffff",
      "link": "#ffffff"
    },
    "button": {
      "background": "#375a7f",
      "text": "#ffffff",
      "border": "transparent"
    }
  },
  "type": "info",
  "content": {
    "message": "This website uses cookies to log in and remember your account.<br/>Please check our cookie policy. <a href=./terms-and-conditions>About</a>",
    "dismiss": "Got it!",
    "deny": "Refuse cookies",
    "link": "Learn more",
    "href": "https://cookiesandyou.com",
    "policy": "Cookie Policy"
  },
  "dismissOnTimeout": 20000,
  "dismissOnWindowClick": true,
  "showLink": false
};

// We use this MediaTypeInterceptor to say to every request that we should send json
@Injectable()
export class MediaTypeInterceptor implements HttpInterceptor { 
    
    constructor() {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
        const headerName = "Content-Type";
        const headerValue = "application/json"
        req = req.clone({ headers: req.headers.set(headerName, headerValue) });
      return next.handle(req);
    }
}

// We use this XsrfInterceptor to inject CSRF Token in every outgoing request
@Injectable()
export class HttpXsrfInterceptor implements HttpInterceptor { 
    
    constructor(private tokenExtractor: HttpXsrfTokenExtractor) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
      if(req.withCredentials)
      {
        const headerName = "X-CSRFToken";
        let token = this.tokenExtractor.getToken() as string;
        if (token !== null && !req.headers.has(headerName)) 
        {
            req = req.clone({ headers: req.headers.set(headerName, token) });
        }
      }
      return next.handle(req);
    }
}

@NgModule({
  declarations: [
    AppComponent,
    HomeVideoComponent,
    AboutVideoComponent,
    MagicElementsComponent,
    SocialOrderComponent,
    GameplayActivitiesComponent,
    WeaponParallaxComponent,
    WeaponTemplatedListComponent,
    AccountComponent,
    FrontPageComponent,
    LoginComponent,
    RegisterComponent,
    AccountOverviewComponent,
    AccountSecurityComponent,
    AccountAssistanceComponent,
    AccountSettingsComponent,
    AccountOrdersComponent,
    OrdersTemplatedListComponent,
    ShopComponent,
    ProductsTemplatedListComponent,
    HudComponent,
    FooterComponent,
    NewsletterSubscribeComponent,
    FAQComponent,
    AboutUsComponent,
    ProductDetailsComponent,
    CookieComponent,
    ProductComponent,
    LoreComponent,
    RedirectComponent,
    ThanksComponent,
    TermsAndConditionsComponent,
    TermsAndConditionsModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    NgbModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({ cookieName: 'csrftoken', headerName: 'X-CSRFToken' }), // this is same as header.append("x-csrftoken", document.cookie.match('(^|;)\\s*' + 'csrftoken' + '\\s*=\\s*([^;]+)')?.pop() || '')
    NgxStripeModule.forRoot(environment.production ? 'pk_live_51KVjFuGYOk7O6RuC3yyjBoWc5RpgPx6AFF7xIUSHKe13Ezqld9hvSiKIiRo38geTDYA49HxQhz79FbHz9y2lR4jE00QIdY3nxb' : 'pk_test_51KVjFuGYOk7O6RuCJl96pRyoLFVNrV3q9zlYELKaCMrofcIMXCTMZyXWANdCDHAli455ZBwoXycNFlHljBd3i0aV00q0VjXAF2'),
    NgcCookieConsentModule.forRoot(cookieConfig),
    NgCircleProgressModule.forRoot({ //https://www.npmjs.com/package/ng-circle-progress
      "space": 3,
      "outerStrokeGradient": false,
      "outerStrokeWidth": 6,
      "titleFontSize": "30",
      "radius": 80,
      "backgroundPadding": 0,
      "units": "%",
      "unitsColor": "#FFFFFF",
      "subtitleColor": "#FFFFFF",
      "outerStrokeColor": "#00BC8C",
      "titleColor":"#FFFFFF",
      "outerStrokeGradientStopColor": "#53a9ff",
      "innerStrokeColor": "#00BC8C",
      "innerStrokeWidth": 6,
      "title": "auto",
      "subtitle": "Finished",
      "animateTitle": true,
      "animationDuration": 600,
      "showUnits": true,
      "showSubtitle": true,
      "subtitleFontSize": "20",
      "showBackground": false,
      "responsive": true,
      "animation" : true,
      "renderOnClick" : false,
      "lazy": false
    })
  ],
  providers: [
    AuthGuardService,
    CookieService,
    [
      //{ provide: HTTP_INTERCEPTORS, useClass: MediaTypeInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true }, 
      { provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptor, multi: true }
    ]
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
