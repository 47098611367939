<footer class="w-100 py-4 flex-shrink-0 " style="background-image: url(assets/assets_web_2/bg-footer.webp);">
  <div class="container">
    <div class="row mx-auto">

      <div class="col my-auto">

        <div style="text-align: center;">
          <!--
      <a class="px-2" href="https://www.kickstarter.com" target="_blank" rel="noopener">
        <svg width="35px" height="35px"><image loading="lazy" href="assets/assets_web_2/Icon_awesome-kickstarter-k.svg" alt="Icon" width="35px" height="35px" /></svg>
      </a>
        -->
          <a class="px-2" href="https://discord.gg/Tph9tp4Qan" target="_blank" rel="noopener">
            <svg width="35px" height="35px"><image loading="lazy" href="assets/assets_web_2/discord.svg" alt="Icon" width="35px" height="35px" /></svg>
          </a>
          <a class="px-2" href="https://www.youtube.com/channel/UCLhG8CiCtx3HCk2XLYZStNg" target="_blank" rel="noopener">
            <svg width="35px" height="35px"><image loading="lazy" href="assets/assets_web_2/Icon_awesome-youtube.svg" alt="Icon" width="35px" height="35px" /></svg>
          </a>
          <a class="px-2" href="https://www.instagram.com/playsoulcast/" target="_blank" rel="noopener">
            <svg width="35px" height="35px"><image loading="lazy" href="assets/assets_web_2/instagram.svg" alt="Icon" width="35px" height="35px" /></svg>
          </a>
          <a class="px-2" href="https://www.facebook.com/profile.php?id=100089858125789" target="_blank" rel="noopener">
            <svg width="35px" height="35px"><image loading="lazy" href="assets/assets_web_2/facebook.svg" alt="Icon" width="35px" height="35px" /></svg>
          </a>
          <a class="px-2" href="https://twitter.com/PlaySoulCast" target="_blank" rel="noopener">
            <svg width="35px" height="35px"><image loading="lazy" href="assets/assets_web_2/Icon_awesome-twitter.svg" alt="Icon" width="35px" height="35px" /></svg>
          </a>
        </div>
        <div class="pt-4" style="text-align: center;">
          <ul class="list-unstyled text-muted">
            <li><p style="cursor: pointer; margin: 0" href="#">Home</p></li>
            <li><p style="cursor: pointer; margin: 0 " routerLink="/faq">FAQ</p></li>
            <li><p style="cursor: pointer; margin: 0 " routerLink="/about-us">About us</p></li>
            <li><p style="cursor: pointer; margin: 0 " routerLink="/terms-and-conditions">Terms & Conditions</p></li>
            <li><p style="cursor: pointer; margin: 0 " href="assets/" download="SoulCast_Press_Kit.zip">Presskit</p></li>
          </ul>
        </div>
      </div>

      <div class="col order-md-first align-middle">
        <img style="width:100%;max-width:100vw;height:auto" src="assets/OneShotLogo_OneLine.png" alt="OnshotStudioLogo" id="OneShotStudioLogo">
        <p class="text-muted mb-0" style="text-align: center; font-size:15px;"> SoulCast &copy; ONESHOT STUDIOS Copyright. All rights reserved. </p>
        <div style="text-align: center; ">
          <img src="assets/AgeRating/pegi18.webp" alt="PEGI" class="p-1" style="height: 65px; width: 65px; display: inline-block;" />
          <img src="assets/AgeRating/pegi_violance.webp" alt="PEGI" class="mx-auto p-1" style="height: 65px;width:65px; display: inline-block;" />
          <img src="assets/AgeRating/pegi_language.webp" alt="PEGI" class="mx-auto p-1" style="height: 65px; width: 65px; display: inline-block;" />
          <img src="assets/AgeRating/pegi_random.webp" alt="PEGI" class="mx-auto p-1" style="height: 65px; width: 65px; display: inline-block;" />
          <img src="assets/AgeRating/pegi_buy.webp" alt="PEGI" class="mx-auto p-1" style="height: 65px; width: 65px; display: inline-block;" />
        </div>
      </div>

      <a *ngIf="isMobile" href="https://www.paypal.com/donate/?hosted_button_id=FGA6KKC23GJ56" style="text-decoration:none;">
        <div class="coffee" style="font-size:12px">
          <p style="color: #ff972e;margin:0">Buy us a Coffee<br />(Donate)</p>
          <img style="width:100px; max-width:10vw;transform:translate(5%,0)" src="assets/assets_web_2/coffee.webp" />
        </div>
      </a>

    </div>
  </div>

  <a *ngIf="!isMobile" href="https://www.paypal.com/donate/?hosted_button_id=FGA6KKC23GJ56" style="text-decoration:none;position:absolute;right:5vw;bottom:5vh">
    <div class="coffee" style="font-size:12px">
      <p style="color: #ff972e;margin:0">Buy us a Coffee<br />(Donate)</p>
      <img style="width:100px; max-width:10vw;transform:translate(5%,0)" src="assets/assets_web_2/coffee.webp" />
    </div>
  </a>

</footer>
