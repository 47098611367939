


<!--<div *ngIf="!isMobile()" [@toolbar-visibility]="toolbarVisible || !ToggleNav ? 'toolbar-visible' : 'toolbar-hidden'" class="d-flex top-toolbar">

  <img style="height: 65px; width: auto; padding-left:100px " src="assets/OneShotLogo_OneLine.png" alt="OnshotStudioLogo" id="OneShotStudioLogo">

  <nav class="navbar navbar-expand-lg" style="position: fixed; z-index: 3000; width: 100vw; padding:0;">
    <div class="container-fluid justify-content-right">
      <div class="nav">
        <a class="nav-link text-reset px-1 px-lg-2" routerLink="/" aria-label="H">HOME</a>
        <a class="nav-link text-reset px-1 px-lg-2" routerLink="/shop" aria-label="Shop">SHOP</a>
        <a class="nav-link text-reset px-1 px-lg-2" routerLink="/faq" aria-label="FAQ">FAQ</a>
        <a *ngIf="!user.isLogin" class="nav-link text-reset px-1 px-lg-2" style="cursor: pointer;" (click)="toggleSidenav();">LOGIN</a>
        <a *ngIf="user.isLogin" routerLink="/account" class="nav-link text-reset px-1 px-lg-2" style="cursor: pointer;">ACCOUNT</a>
      </div>
    </div>
  </nav>
</div>-->

<nav *ngIf="!responsiveService.isMobile()" [@toolbar-visibility]="toolbarVisible || !ToggleNav ? 'toolbar-visible' : 'toolbar-hidden'" class="navbar fixed-top navbar-expand-lg top-toolbar">
  <img style="height: 100%; width: auto; padding-left:5%; position: absolute;" src="assets/OneShotLogo_OneLine.png" alt="OnshotStudioLogo" id="OneShotStudioLogo">
  <div class="d-flex justify-content-end" style="position: absolute; width: 100%; padding-right: 5%; gap: 10px;">
    <a class="nav-link text-reset px-1 px-lg-2" routerLink="/" aria-label="H">HOME</a>
    <a class="nav-link text-reset px-1 px-lg-2" routerLink="/shop" aria-label="Shop">SHOP</a>
    <a class="nav-link text-reset px-1 px-lg-2" routerLink="/faq" aria-label="FAQ">FAQ</a>
    <a *ngIf="!user.isLogin" class="nav-link text-reset px-1 px-lg-2" style="cursor: pointer;" (click)="toggleSidenav();">LOGIN</a>
    <a *ngIf="user.isLogin" routerLink="/account" class="nav-link text-reset px-1 px-lg-2" style="cursor: pointer;">ACCOUNT</a>
  </div>
</nav>

<nav *ngIf="responsiveService.isMobile()" class="navbar fixed-top navbar-expand-lg" style="z-index: 99999;">
  <div class="container-fluid">
    <button type="button" style="background-color: transparent; border-color: transparent; " (click)="toggleSidenav();">
      <div class="d-flex flex-column">
        <img src="/assets/Icon_login.webp">
        <span style="color:white">Login</span>
      </div>
    </button>
  </div>
</nav>

<nav *ngIf="responsiveService.isMobile()" class="navbar fixed-bottom navbar-expand-lg m-auto toolbar" style="z-index: 99;">
  <div class="d-flex justify-content-evenly" style="padding-top: 10px; width: min(100%, 100vw)">
    <a class="nav-link text-reset px-1 px-lg-2" routerLink="/" aria-label="H">Home</a>
    <a class="nav-link text-reset px-1 px-lg-2" routerLink="/shop" aria-label="Shop">Shop</a>
    <a class="nav-link text-reset px-1 px-lg-2" routerLink="/faq" aria-label="FAQ">FAQ</a>
    <button type="button" style="background-color: red; border-color: transparent; border-radius: 0; color:white;" (click)="toggleSidenav();">
      <span>Kickstarter</span>
    </button>
  </div>
</nav>

<mat-sidenav-container style="z-index: 99999;">
  <!-- Sidenav -->
  <mat-sidenav  id="sidenav" #sidenav [mode]="responsiveService.isMobile() ? 'over' : 'side'" [(opened)]="opened || openedbymouse" [fixedInViewport]="true" position="start"
               [fixedTopGap] style="min-width:320px">
    <button type="button" class="close px-2" style="position:absolute;top:0;right:0" aria-label="Close" (click)="toggleSidenav()">
      <span aria-hidden="true" style="color:white;font-size:40px">&times;</span>
    </button>
    <mat-nav-list style="min-width:250px">
      <div mat-list-item style="text-align:center">
        <app-login></app-login>
      </div>
      <div class="d-flex justify-content-center mb-2">
        <img loading="lazy" src="assets/SoulCastSmall.webp" alt="Logo" class="m-auto" style="bottom: 20px; width: 10vw; min-width: 200px; height: auto; filter: drop-shadow(5px 5px 5px #000000); ">
      </div>
    </mat-nav-list>
  </mat-sidenav>
</mat-sidenav-container>
