import { Component, OnInit, AfterViewInit, ViewChild, HostListener, ElementRef } from '@angular/core';
import { style, state, animate, transition, trigger } from '@angular/animations';
import { ResponsiveService } from 'src/app/services/responsive.service';
import Player from '@vimeo/player';

@Component({
  selector: 'app-home-video',
  templateUrl: './home-video.component.html',
  styleUrls: ['./home-video.component.css'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(200, style({ opacity: 1 }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(200, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class HomeVideoComponent implements OnInit, AfterViewInit {

  constructor(protected responsiveService: ResponsiveService) { }

  @ViewChild('playerHomeContainer') playerContainer: ElementRef;
  videoPlayer;
  
  ngOnInit(): void {
  }

  ngAfterViewInit() {
    let options;
    let _height = document.getElementById('SoulCastHomeVideo')?.clientHeight;
    let _width = document.getElementById('SoulCastHomeVideo')?.clientWidth;
    if (_height! > _width!) {
      options = {
        id: 777643287,
        height: _height,
        loop: true,
        background: true,
        controls: false,
        autoplay: true,
        autopause: false
      };
    }
    else {
      options = {
        id: 777643287,
        width: _width,
        loop: true,
        background: true,
        controls: false,
        autoplay: true,
        autopause:false
      };
    }
    
    //document.getElementById('SoulCastHomeVideo')!.style.backgroundColor = "black";
    if (this.responsiveService.isMobile())
      return;
    this.videoPlayer = new Player(this.playerContainer.nativeElement, options);
    /*
    const fadeOut = function (this, data) {
      if (data.duration - data.seconds <= 2) {
        gsap.fromTo(document.getElementById('SoulCastHomeVideo'), 2.0, { autoAlpha: 1 }, { autoAlpha: 0 });
        document.getElementById('SoulCastHomeVideo')!.style.removeProperty('background-color');
        this.off('timeupdate', fadeOut)
      }
    }
    const fadeIn = function (this, data) {
      if (data.percent >= 0.95) {
        this.loadVideo(777643287);
        gsap.fromTo(document.getElementById('SoulCastHomeVideo'), 2.0, { autoAlpha: 0 }, { autoAlpha: 1 });
        this.play();
        this.off('timeupdate', fadeIn)
      }
    }

    this.videoPlayer.on('timeupdate', fadeOut);
    this.videoPlayer.on('timeupdate', fadeIn);
    */
    // IntersectionObserver
    let setupIntersectionObserver = function (target) {
      var optionsIntersectionObserver = { threshold: 0.1 }
      const callback = function (entries) {
        if (entries[0].isIntersecting) {
          target.videoPlayer.play();
        } else {
          target.videoPlayer.pause();
        }
      }
      var observer = new IntersectionObserver(callback, optionsIntersectionObserver);
      observer.observe(target.videoPlayer.element);
    };

    let fadeIn = function () {
      gsap.fromTo('.video', 4.0, { autoAlpha: 0 }, { autoAlpha: 1, ease: "power4.out" });
    }
    this.videoPlayer.ready().then(setupIntersectionObserver(this));
    this.videoPlayer.ready().then(fadeIn());
  }

  showFirstBorder: boolean = false;

  @HostListener("window:scroll", []) onWindowScroll() {
    const verticalOffset = window.pageYOffset
      || document.documentElement.scrollTop
      || document.body.scrollTop || 0;
    this.showFirstBorder = verticalOffset != 0;
  }
}


