<div style="width:100%;">
    <div class="d-flex flex-column">
        <!-- Toolbar container -->
        <div class="d-flex justify-content-center" style="height: 50px; pointer-events: all;">
            <button [ngStyle]="{'opacity': previousWeapon.is_valid ? 1.0 : 0.3}" [disabled]="!previousWeapon.is_valid"  (click)="goToPreviousWeapon()" style="height: 100%; aspect-ratio : 1 / 1; background-color: transparent; margin: 0; border-width: 0;">
                <img src="assets/arrow_128.webp" style="width: 50%; height: auto; transform: scaleX(-1);">
            </button>
            <!-- Title bar -->
            <div [ngStyle]="{'width.px': titleBarWidth, 'height.px': titleBarHeight}" id="TitleContainer" style="height: 100%; display: flex; justify-content: center; clip-path: stroke-box;">
                <div [@switchWeapon]="carrouselState == CarrouselState.Middle ? 'middle-translate' : carrouselState == CarrouselState.Left ? 'left-translate' : 'right-translate'" (@switchWeapon.done)="onSwitchWeaponDone($event)" [ngStyle]="{'width.px': titleCarrouselWidth, 'height.px': titleBarHeight}" style="position: absolute; pointer-events: none;" class="d-flex flex-row">

                    <!-- Previous Weapon Title -->
                    <div [@switchWeapon]="carrouselState == CarrouselState.Right ? 'opaque' : 'transparent'" [ngStyle]="{'width.px': titleBarWidth}" class="d-flex flex-row" style="height: 100%;">
                        <div style="height: 100%; aspect-ratio: 1/1;">
                            <svg height="100%" width="100%" preserveAspectRatio="xMaxYMax">
                                <image [attr.xlink:href]="previousWeapon.icon_url" x="0" y="-2" loading="lazy" alt="Icon" width="100%" height="100%" />
                            </svg>
                        </div>
                        <div class="d-flex align-items-center" style="width: 100%;">
                            <span style="text-align: center; width:100%; vertical-align: center;">
                                {{previousWeapon.name}}
                            </span>
                        </div>
                    </div>

                    <!-- Current Weapon Title -->
                    <div [@switchWeapon]="carrouselState == CarrouselState.Middle ? 'opaque' : 'transparent'" [ngStyle]="{'width.px': titleBarWidth}" class="d-flex flex-row" style="height: 100%;">
                        <div style="height: 100%; aspect-ratio: 1/1;">
                            <svg height="100%" width="100%" preserveAspectRatio="xMaxYMax">
                                <image [attr.xlink:href]="currentWeapon.icon_url" x="0" y="-2" loading="lazy" alt="Icon" width="100%" height="100%" />
                            </svg>
                        </div>
                        <div class="d-flex align-items-center" style="width: 100%;">
                            <span style="text-align: center; width:100%; vertical-align: center;">
                                {{currentWeapon.name}}
                            </span>
                        </div>
                    </div>

                    <!-- Next Weapon Title -->
                    <div [@switchWeapon]="carrouselState == CarrouselState.Left ? 'opaque' : 'transparent'" [ngStyle]="{'width.px': titleBarWidth}" class="d-flex flex-row" style="height: 100%;">
                        <div style="height: 100%; aspect-ratio: 1/1;">
                            <svg height="100%" width="100%" preserveAspectRatio="xMaxYMax">
                                <image [attr.xlink:href]="nextWeapon.icon_url" x="0" y="-2" loading="lazy" alt="Icon" width="100%" height="100%" />
                            </svg>
                        </div>
                        <div class="d-flex align-items-center" style="width: 100%;">
                            <span style="text-align: center; width:100%; vertical-align: center;">
                                {{nextWeapon.name}}
                            </span>
                        </div>
                    </div>


                </div>
            </div>
            <button [ngStyle]="{'opacity': nextWeapon.is_valid ? 1.0 : 0.3}" [disabled]="!nextWeapon.is_valid" (click)="goToNextWeapon()" style="height: 100%; aspect-ratio : 1 / 1; background-color: transparent; margin: 0; border-width: 0;">
                <img src="assets/arrow_128.webp" style="width: 50%; height: auto;">
            </button>
        </div>
        <!-- Weapon Image -->
        <div class="d-flex justify-content-center" style="width: 100%; margin-top:20px;">
            <div [ngStyle]="{'width.px': weaponImageWidth, 'height.px': weaponImageHeight }" style="display: flex; clip-path: fill-box; justify-content: center;">
                <div [@switchWeapon]="carrouselState == CarrouselState.Middle ? 'middle-translate' : carrouselState == CarrouselState.Left ? 'left-translate' : 'right-translate'" [ngStyle]="{'width.px': weaponImageCarrouselWidth, 'height.px': weaponImageHeight}" style="position: absolute; pointer-events: none;" class="d-flex flex-row">
                    <img [ngStyle]="{'width.px': weaponImageWidth, 'height.px': weaponImageHeight}" src={{previousWeapon.image_url}}>
                    <img [ngStyle]="{'width.px': weaponImageWidth, 'height.px': weaponImageHeight}" src={{currentWeapon.image_url}}>
                    <img [ngStyle]="{'width.px': weaponImageWidth, 'height.px': weaponImageHeight}" src={{nextWeapon.image_url}}>
                </div>
            </div>
        </div>
        <!-- Weapon Description -->
        <div class="d-flex justify-content-center" style="width: 100%; margin-top:20px; margin-bottom: 20px;">
            <span style="text-align: center; width:70%;">
                {{currentWeapon.description}}
            </span>
        </div>
    </div>
</div>
