import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, Input, HostBinding, HostListener } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AccountService } from 'src/app/services/account.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { User, DefaultUser } from 'src/app/login/login.component';
import { Subscription } from 'rxjs';
import {SidenavService} from '../services/sidenav.service';
import { trigger, state, style, animate, transition} from '@angular/animations';

@Component({
  selector: 'app-hud',
  templateUrl: './hud.component.html',
  styleUrls: ['./hud.component.css'],
  animations: [
    trigger('toolbar-visibility', [
      // ...
      state('toolbar-visible', style({
        top: '0px',
      })),
      state('toolbar-hidden', style({
        top: '-65px',
      })),
      state('logo-visible', style({
        opacity: '1.0',
      })),
      state('logo-hidden', style({
        opacity: '0.0',
      })),
      transition('toolbar-visible <=> toolbar-hidden', [
        animate('0.2s')
      ]),
      transition('logo-visible <=> logo-hidden', [
        animate('0.2s')
      ])
    ]),
  ]
})
export class HudComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(private account: AccountService, private sidenavService: SidenavService, protected responsiveService: ResponsiveService) { }

  user: User;
  userSub: Subscription;
  sidenavOpenedSub: Subscription;
  opened: boolean = false;
  openedbymouse: boolean = false;
  sidenavOpenedbymouseSub: Subscription;
  toolbarVisible: boolean = true;
  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  scrollPosition: number;
  @Input()
  displaySocialLinks: boolean = true;
  ToggleNav: boolean = true;

  ngOnInit(): void {
    this.scrollPosition = 0;
    this.sidenav.fixedTopGap = 0;
    this.initializeUser();
    this.sidenavOpenedSub = this.sidenavService.opened.subscribe({
      next: _ => this.opened = _,
      error: err => console.log(err)
    })
    this.sidenavOpenedbymouseSub = this.sidenavService.openedbymouse.subscribe({
      next: _ => this.openedbymouse = _,
      error: err => console.log(err)
    })
  }

  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.sidenav);
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
    this.sidenavOpenedSub.unsubscribe();
    this.sidenavOpenedbymouseSub.unsubscribe();
  }

  initializeUser() {
    this.user = DefaultUser
    this.userSub = this.account.userObservable.subscribe(
      (next: User) => {
        if (next != null)
          this.user = next;
        else
          this.user = DefaultUser
      },
      (error) => {
        console.log(error);
      }
    )
  }

  toggleSidenav() {
    this.sidenavService.toggle();
  }

  CollapseLogo() {
    this.ToggleNav = !this.ToggleNav;
  }

  @HostListener('window:mousemove', ['$event']) // for window mouse move events
  onMouseMove(event) {
    if (!this.sidenav) return;
    if (event.clientX < window.innerWidth * .05 + this.sidenav._getWidth() * +!!this.openedbymouse) { this.sidenavService.setOpenedbymouse(true);}
    else { this.sidenavService.setOpenedbymouse(false);}
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    if (!this.responsiveService.isMobile())
      this.toolbarVisible = this.scrollPosition > window.scrollY || window.scrollY == 0;
    this.scrollPosition = window.scrollY;
  }

}
