import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  isMobile = false;

  ngOnInit() {
    if (window.screen.width <= 749) {
      this.isMobile = true;
    }
  }

  @HostListener("window:resize", [])
  onResize() {
    var width = window.screen.width;
    this.isMobile = width <= 749;
  }
}
