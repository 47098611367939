import { Injectable } from '@angular/core';
import { unitize } from 'gsap';

@Injectable({
  providedIn: 'root'
})
export class ResponsiveService {

  static responsive_width: number;
  static mobile_background_image_x_offset: number;
  static mobile_social_media_bar_height: number;
  static mobile_about_video_offset: number;
  static mobile_about_video_height: number;
  static mobile_weapon_parallax_offset: number;
  static mobile_weapon_parallax_height: number;
  static mobile_background_image_height: number;
  static mobile_background_image_width: number;

  public class = ResponsiveService;

  constructor() 
   {
      ResponsiveService.responsive_width = 1080;
      ResponsiveService.mobile_background_image_x_offset = 0;
      ResponsiveService.mobile_social_media_bar_height = 150;
      ResponsiveService.mobile_about_video_offset = 0;
      ResponsiveService.mobile_about_video_height = 500;
      ResponsiveService.mobile_weapon_parallax_offset = 0;
      ResponsiveService.mobile_weapon_parallax_height = 500;
      ResponsiveService.mobile_background_image_width = 673;
      ResponsiveService.mobile_background_image_height = 2710;
      ResponsiveService._updateOffsets();
   }

  initAboutVideoResizeObserver(aboutVideoContainer: HTMLElement)
  {
    const observer = new ResizeObserver((entries, observer) => {
      entries.forEach((entry, index) => {
        const { inlineSize: width, blockSize: height } = entry.contentBoxSize[0];
        ResponsiveService.mobile_about_video_height = height;
        ResponsiveService._updateOffsets();
      });
    });
    
    observer.observe(aboutVideoContainer);
    ResponsiveService.mobile_about_video_height = aboutVideoContainer.offsetHeight;
    ResponsiveService._updateOffsets();
  }

  initWeaponParallaxResizeObserver(weaponParallaxContainer: HTMLElement)
  {
    const observer = new ResizeObserver((entries, observer) => {
      entries.forEach((entry, index) => {
        const { inlineSize: width, blockSize: height } = entry.contentBoxSize[0];
        ResponsiveService.mobile_weapon_parallax_height = height;
        ResponsiveService._updateOffsets();
      });
    });
    
    observer.observe(weaponParallaxContainer);
    ResponsiveService.mobile_weapon_parallax_height = weaponParallaxContainer.offsetHeight;
    ResponsiveService._updateOffsets();
  }

  private static _updateOffsets() 
  {
    ResponsiveService.mobile_about_video_offset = ResponsiveService.mobile_social_media_bar_height;
    ResponsiveService.mobile_weapon_parallax_offset = ResponsiveService.mobile_about_video_offset + ResponsiveService.mobile_about_video_height;
  }

  isMobile() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return width <= ResponsiveService.responsive_width;
  }
}
