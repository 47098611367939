import { Component, OnInit, AfterViewInit } from '@angular/core';
import { trigger, state, style, animate, transition, AnimationEvent } from '@angular/animations';

export interface Weapon {
  name: string;
  description: string;
  icon_url: string;
  image_url: string;
  is_valid: boolean;
}

function CreateDefaultWeapon()
{
  return {
    name: "",
    description: "",
    icon_url: "",
    image_url: "",
    is_valid: false
  }
}

const weapons: Array<Weapon> = [
  {
    name: "Sword",
    description: "The weapon of choice if you like it close and personal. Combo your opponents to death, and launch it with a chain to move around the map quickly.",
    icon_url: "assets/assets_web_2/sword.svg",
    image_url: "assets/weapons/Sword.webp",
    is_valid: true
  },
  {
    name: "Sword And Shield",
    description: "Block projectiles and stun your foes while you wait for the right moment to strike back..",
    icon_url: "assets/assets_web_2/Sword_and_shield.svg",
    image_url: "assets/weapons/Shield.webp",
    is_valid: true
  },
  {
    name: "Scythe",
    description: "In a world where mobility and reach is the key to success, the scythe can give you an edge in combat with its special movement abilities...",
    icon_url: "assets/assets_web_2/Scythe.svg",
    image_url: "assets/weapons/Scythe.webp",
    is_valid: true
  }
]

enum CarrouselState {
  Middle = 1,
  Left = 2,
  Right = 3
}

@Component({
  selector: 'app-weapon-templated-list',
  animations: [
    trigger('switchWeapon', [
      // ...
      state('middle-translate', style({
        transform: 'translateX(0%)'
      })),
      state('left-translate', style({
        transform: 'translateX(33.33%)'
      })),
      state('right-translate', style({
        transform: 'translateX(-33.33%)'
      })),
      state('opaque', style({
        opacity: 1.0
      })),
      state('transparent', style({
        opacity: 0.0
      })),
      transition('middle-translate => left-translate', [
        animate('0.2s ease-out')
      ]),
      transition('middle-translate => right-translate', [
        animate('0.2s ease-out')
      ]),
      transition('left-translate => middle-translate', [
        animate('0s')
      ]),
      transition('right-translate => middle-translate', [
        animate('0s')
      ]),
      transition('opaque <=> transparent', [
        animate('1.0s ease-out')
      ])
    ]),
  ],
  templateUrl: './weapon-templated-list.component.html',
  styleUrls: ['./weapon-templated-list.component.css']
})
export class WeaponTemplatedListComponent implements OnInit, AfterViewInit {

  currentWeaponIndex: number;
  carrouselState: CarrouselState;
  CarrouselState = CarrouselState;

  currentWeapon: Weapon;
  previousWeapon: Weapon;
  nextWeapon: Weapon;

  titleBarWidth: number;
  titleBarHeight: number;
  titleCarrouselWidth: number;

  weaponImageWidth: number;
  weaponImageHeight: number;
  weaponImageCarrouselWidth: number;

  constructor() {
    this.currentWeaponIndex = 0;
    this.carrouselState = CarrouselState.Middle;
    this.titleBarWidth = 200;
    this.titleBarHeight = 50;
    this.titleCarrouselWidth = 3 * this.titleBarWidth;
    this.weaponImageWidth = 430;
    this.weaponImageHeight = 367;
    this.weaponImageCarrouselWidth = 3 * this.weaponImageWidth;
  }

  ngOnInit(): void 
  {
    this.updateCurrentWeapon();
  }

  ngAfterViewInit(): void 
  {
    this.updateCurrentWeapon();
  }

  onSwitchWeaponDone(event: AnimationEvent)
  {
    if(this.carrouselState != CarrouselState.Middle)
    {
      console.log("onSwitchWeaponDone");
      this.updateCurrentWeapon();
      this.carrouselState = CarrouselState.Middle;
    }
  }

  goToPreviousWeapon() : void
  {
    if(this.currentWeaponIndex > 0)
    {
      this.currentWeaponIndex--;
      this.carrouselState = CarrouselState.Left;
    }
  }

  goToNextWeapon() : void
  {
    if(this.currentWeaponIndex < weapons.length - 1)
    {
      this.currentWeaponIndex++;
      this.carrouselState = CarrouselState.Right;
    }
  }

  updateCurrentWeapon(): void 
  {
      this.currentWeapon = weapons[this.currentWeaponIndex]
      let previousIndex = this.currentWeaponIndex - 1;
      if (previousIndex < 0) 
      {
        this.previousWeapon = CreateDefaultWeapon();
      }
      else
      {
        this.previousWeapon = weapons[previousIndex];
      }


      let nextIndex = this.currentWeaponIndex + 1;
      if (nextIndex >= weapons.length) 
      {
        this.nextWeapon = CreateDefaultWeapon();
      }
      else
      {
        this.nextWeapon = weapons[nextIndex];
      }
  }


}
